import React from 'react';

import PageLayout from '../layouts/page/page.layout';
import FinalContent from '../components/final-content/final-content.comp';
import { useIsMobile } from '../utils/hooks/use-is-mobile';

const Final = ({location}) => {
	const isMobile = useIsMobile();

	return (
		<PageLayout
			subTopic={isMobile ? 'Good Job' : 'Good Job!'}
			title='Final preview'
			location
		>
			<FinalContent />
		</PageLayout>
	);
};

export default Final;
