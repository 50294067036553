import { css } from 'styled-components';

export const getGreenFrameStyles = () => css`
	&::after {
		/* content: '';
		position: absolute;
		top: 15%;
		left: 5%;
		width: 90%;
		height: 75%;
		background: url(/images/oval-7.svg);
		background-size: 170%;
		background-position: center;
		background-repeat: no-repeat;
		overflow: visible; */



		/* content: '';
		position: absolute;
		top: 17%;
		left: -4%;
		width: 108%;
		height: 75%;
		background: url(/images/oval-7.svg);
		background-size: 133% 180%;
		background-position: center;
		background-repeat: no-repeat;
		overflow: visible; */
	}
`;
